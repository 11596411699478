/*@import url('https://use.typekit.net/btf8mzw.css');*/

* {
    box-sizing: border-box;
    font-family: source-sans-pro, sans-serif;
    font-weight: 400;
}
.board-container {
    position: relative;
    height: calc(100vh - 80px);
    position: relative;
    margin: 40px;
    display: flex;
    align-items:center;
    justify-content: center;
}
.board-container > div {
    /*top: 50%;
    left: 50%;
    display: inline-block;
    position: absolute;
    transform: translate(-50%, -50%);*/
    display: inline-block;
    position: relative;
}
.board-container .scoreboard {
    height: 560px;
    width: 220px;
    background-color: #F7DEC5; /*#ECCDAE #8AD9F3*/
    margin-left: 40px;
    padding: 0px 25px;
}
.scoreboard h3 {
    font-size: 22px;
    margin-bottom: 5px;
}
.pieces_captured {
    margin-top: 9px;
}

.waiting-background {
    background-color: #ececec8c;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.waiting-message {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #d5b596/*#d1b293 rgb(138, 217, 243)*/;
    padding: 0px 30px 15px;
    width: 100%;
}
/*.waiting-message h2 {
    font-weight: 700;
}*/
.waiting-message a {
    color: black;
}
.waiting-message .waiting-link {
    padding: 10px 0px;
    display: block;
}
.waiting-message .waiting-link span {
    text-decoration: underline;
    cursor: pointer;
}
.waiting-message h2 {
    line-height: 1.5;
}
.board-container:not(.waiting-for-second-player) .waiting.invite_player,
.other_player_next, .action2 .waiting.invite_player {
    display: none;
}
.board-container:not(.waiting-for-second-player).board-disabled .board-container-main, .board-checkmate {
    cursor: not-allowed;
}
.show {
    display: block !important;
}
.board {
    display: block;
    border-spacing: 0px;
}
.square {
    width: 70px;
    height: 70px;
    /*border: 1px solid #CCCCCC;*/
    position: relative;
}
.square.light {
    background-color: #ffebd4; /*#FFE1BE*/
}
.square.dark {
    background-color: #eac7a3; /*#e3ad76 #daa168 #d38d47;*/
}

.square:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    display: block;
}
.possible_field:after {
    /*-webkit-box-shadow: inset 0px 0px 18px 9px rgba(60, 60, 60, 0.37); 
    box-shadow: inset 0px 0px 18px 9px rgba(60, 60, 60, 0.37);*/
    /*box-shadow: inset 0px 0px 16px 10px #FFBC00;*/
    /*background-color: #FFBC007A;*/
    /*margin: 20px;
    background-color: #9D754F; / *#9F6835 #5E330B #6B93F2 #5783EC #915D1A #AD1919 #915F12* /
    width: calc(100% - 40px);
    height: calc(100% - 40px);*/
    margin: 22.5px;
    background-color: #A17C59;
    width: calc(100% - 45px);
    height: calc(100% - 45px);
}
/*.selected_field:after {
    /*background-color: #85a6ff;* /
    /*box-shadow: inset 0px 0px 16px 10px #85a6ff;* /
}*/
.capture_piece:after {
    /*margin: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);*/
    margin: 7px;
    width: calc(100% - 14px);
    height: calc(100% - 14px);
}
.capture_piece.light:after {
    background-color: #f2a170; /*#f8936c #daa383 #ff9f9f #ff8585*/
}
.capture_piece.dark:after {
    background-color: #f8a16c; /*#f08e68 #d69d7b*/
}

.piece {
    position: absolute;
    width: 70px;
    height: 70px;
    /*transition: all .5s;*/
    display: flex;
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
/*.piece.animated {*/
.piece:not(.no-animation) {
    transition: all .5s;
}
.piece img {
    height: 50px;
    width: auto;
    display: block;
    margin: auto;
}

.checkmate_overlay {
    background-color: rgb(138, 217, 243);
    /*display: flex;*/
    cursor: default;

    /*bottom*/
    height: 0;
    transition: all .2s ease-out;
    overflow: hidden;

    /*side* /
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;*/
}
/*.checkmate_overlay:not(.shown) {
    /*display: none;* /
    height: 0px;
}*/
.checkmate_overlay.shown {
    height: 107px;
}
.checkmate_overlay_content {
    padding: 14px 20px 18px;
}
.checkmate_overlay .title {
    /*flex-grow: 1;*/
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 14px;
}
.checkmate_overlay .title span {font-weight: bold;}
.checkmate_overlay .message_buttons {
    display: flex;
}
.waiting.overlay_promotion {
    cursor: default;
}
.overlay_promotion:not(.shown) {
    display: none;
}
.piece-selection {
    display: flex;
    justify-content: center;
    margin: 24.5px 0px 0px;
}
.piece-selection div {
    background-color: #f5dcc0/*#ffebd4*/;
    width: 70px;
    height: 70px;
    margin: 10px;
    background-repeat: no-repeat;
    background-size: auto 50px;
    background-position: center;
    cursor: pointer;
}
.overlay_promotion .waiting-message {
    height: 280px;
}
.overlay_promotion h2 {
    margin-top: 25px;
}
.piece-selection div.piece-selection-queen-false {background-image: url('/img/4_white.svg');}
.piece-selection div.piece-selection-rook-false {background-image: url('/img/1_white.svg');}
.piece-selection div.piece-selection-bishop-false {background-image: url('/img/3_white.svg');}
.piece-selection div.piece-selection-knight-false {background-image: url('/img/2_white.svg');}

.piece-selection div.piece-selection-queen-true {background-image: url('/img/4_black.svg');}
.piece-selection div.piece-selection-rook-true {background-image: url('/img/1_black.svg');}
.piece-selection div.piece-selection-bishop-true {background-image: url('/img/3_black.svg');}
.piece-selection div.piece-selection-knight-true {background-image: url('/img/2_black.svg');}

.message_button {
    padding: 10px 13px;
    background-color: rgb(231, 249, 255);
    margin-right: 20px;
}

.main-menu {
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.main-menu-button .button {
    background-color: #8AD9F3;
    display: inline-block;
    padding: 7px 10px;
    cursor: default;
    font-size: 24px;
}

.error_box {
    display: none;
    margin: 20px 0px 0px;
    max-width: 270px;
    line-height: 1.3;
}
.main-menu.start-game-error .error_box {
    display: block;
}
.error-message {
    font-family: courier;
    margin: 10px 20px;
    background-color: #EAEAEA;
}